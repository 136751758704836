// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { Fragment } from 'react'

import BeritBanner from 'components/BeritBanner'
import BeritCard from 'components/BeritCard'

type CotizacionVerHeaderProps = {
  cotizacion: any,
  cotizacionNumero: number,
}

const CotizacionVerHeader = ({ cotizacion, cotizacionNumero }: CotizacionVerHeaderProps) => {
  const { contactos, cliente, vendedor } = cotizacion

  return (
    <BeritCard classes="CotizacionHeader">
      <MDBRow>
        <MDBCol size="12">
          <MDBRow>
            <MDBCol size="12" md="6">
              <BeritBanner className="berit-banner-container" />
            </MDBCol>
            <MDBCol size="12" md="6" className="usuario">
              <h2 className="numero-cotizacion">Cotización # {cotizacionNumero} - Ver. 1</h2>
              <div className="usuario-info">
                <p>{vendedor.nombre}</p>
                <p>
                  <a href={`mailto:${vendedor.correo}`}>{vendedor.correo}</a>
                </p>
                <p>
                  <a href={`tel:${vendedor.telefono}`}>Cel. {vendedor.telefono}</a>
                </p>
                <p>
                  <a href="https://beritpublicidad.com/" target="_blank" rel="noreferrer">
                    beritpublicidad.com
                  </a>
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>

        <MDBCol size="12" md="6" className="cliente-contacto">
          <h4 className="cliente">{cliente.nombreComercial}</h4>
          {contactos.map(contacto => (
            <Fragment key={contacto?.contactoId}>
              {contacto ? (
                <Fragment>
                  <p className="contacto">
                    {contacto.nombres} {contacto.apellidos}
                  </p>
                  <p>
                    <a href={`mailto:${contacto.correo}`}>{contacto.correo}</a>
                    {contacto.correo && contacto.telefono && ' / '}
                    <a href={`tel:${contacto.telefono}`}>{contacto.telefono}</a>
                  </p>
                </Fragment>
              ) : (
                ''
              )}
            </Fragment>
          ))}
        </MDBCol>

        <MDBCol size="12" md="6" className="fecha">
          <p>
            <span>Fecha:</span>
            {moment(cotizacion.fecha).format('D [de] MMMM [de] YYYY')}
          </p>
        </MDBCol>
      </MDBRow>
    </BeritCard>
  )
}

export default CotizacionVerHeader
