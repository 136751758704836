// @flow
import { MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm'
import { useMemo } from 'react'
// import { useSelector } from 'react-redux'

import BeritBanner from './BeritBanner'
import BeritDatePicker from 'components/BeritDatePicker'
import BeritInput from 'components/BeritInput'
import BeritSelectObject from 'components/BeritSelectObject'
import CotizacionHeaderSelects from 'components/CotizacionHeaderSelects'
import { useGet } from 'utils'

type CotizacionHeaderProps = {
  form: Form,
}

const CotizacionHeader = ({ form }: CotizacionHeaderProps) => {
  const { control, watch } = form
  const empleados: Empleado[] = useGet('empleados')
  const { cotizacionNumero } = watch()

  const empleadosOptions = useMemo(
    () => empleados.sort((a, b) => a.nombre.localeCompare(b.nombre)),
    [empleados]
  )

  return (
    <section className="mb-4 CotizacionHeader">
      <MDBRow>
        <MDBCol size="12">
          <MDBRow>
            <MDBCol size="12" md="6">
              <BeritBanner />
            </MDBCol>
            {cotizacionNumero > 0 && (
              <MDBCol size="12" md="6">
                <h2 className="numero-cotizacion">Cotización # {cotizacionNumero}</h2>
              </MDBCol>
            )}
          </MDBRow>
        </MDBCol>

        <CotizacionHeaderSelects form={form} />

        <MDBCol size="12" md="6">
          <BeritInput
            label="Numero de Cotización"
            control={control}
            rules={{
              required: 'Este campo es obligatorio',
              valueAsNumber: true,
              validate: { positive: value => parseInt(value) > 0 },
            }}
            type="number"
            name="cotizacionNumero"
          />

          <BeritSelectObject
            control={control}
            label="Vendedor"
            name="vendedor"
            options={empleadosOptions}
            rules="Elige un vendedor"
          />

          <BeritDatePicker
            control={control}
            rules="Esta fecha es obligatoria"
            name="fecha"
            label="Fecha"
          />

          <BeritInput
            label="Tiempo de entrega"
            control={control}
            rules="Este campo es obligatorio"
            name="tiempoEntrega"
          />

          <BeritInput
            label="Días de vigencia"
            control={control}
            type="number"
            rules={{
              required: 'Este campo es obligatorio',
              valueAsNumber: true,
              validate: { positive: value => parseInt(value) > 0 },
            }}
            name="diasVigencia"
          />
        </MDBCol>
      </MDBRow>
    </section>
  )
}

export default (CotizacionHeader: CotizacionHeaderProps => React$Node)
