// @flow
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdbreact/dist/mdbreact.esm'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Crawlers.scss'

import { PROVEEDORES_CRAWLERS } from 'redux/constants'

const getTokens = async () => {
  // console.log('about to timeout')
  await setTimeout(() => {
    // console.log('timeouted')
    return {
      idToken: {
        value: 'astastasfasd',
      },
    }
  }, 1000)
}

const Crawlers = () => {
  const [token, setToken] = useState(null)

  const getToken = async () => {
    // console.log('getToken called')
    const tokens = await /* oktaAuth.tokenManager. */ getTokens()
    // console.log('token gotten', tokens)
    const idToken = tokens && tokens.idToken ? tokens.idToken.value : null
    setToken(idToken)
  }

  useEffect(() => {
    // console.log('getToken() invoked')
    getToken()
  }, [])

  return (
    <div className="Crawlers">
      <h2>Crawlersas</h2>
      <MDBContainer>
        <MDBRow>Token: {token}</MDBRow>

        <MDBRow className="crawlers-container">
          {PROVEEDORES_CRAWLERS.map(({ id, name, lastUpdate, image }) => (
            <MDBCol size="4" md="4" className="mb-4" key={id}>
              <section>
                <Link to={`/admin/crawler/${id}`} className="d-flex justify-content-end">
                  <MDBCard className="crawler-card">
                    <div className="crawl-image">
                      <MDBCardImage
                        className="img-fluid"
                        src={image}
                        waves={true}
                        alt="For Promotional"
                      />
                    </div>
                    <MDBCardBody>
                      <div>
                        <MDBCardTitle>
                          <strong>{name}</strong>
                        </MDBCardTitle>
                        <MDBCardText>
                          Última ejecución: <b>{lastUpdate}</b>
                        </MDBCardText>
                      </div>
                      <MDBBtn color="pink" rounded>
                        Ir al crawler
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </Link>
              </section>
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
    </div>
  )
}

export default (Crawlers: () => React$Node)
