// @flow
import { MDBIcon, MDBSideNav, MDBSideNavLink, MDBSideNavNav } from 'mdbreact/dist/mdbreact.esm'

import logo from 'assets/img/logo-berit.png'
import bg from 'assets/img/sidenav.jpg'

type SideNavigationProps = {
  onLinkClick: any,
  triggerOpening: any,
  breakWidth: number,
}

const SideNavigation = ({ onLinkClick, triggerOpening, breakWidth }: SideNavigationProps) => {
  const isProduction = process.env.NODE_ENV === 'production'

  const routes = [
    {
      label: 'Cotizaciones',
      to: '/admin/cotizaciones',
      icon: 'file-invoice-dollar',
    },
    {
      label: 'Clientes',
      to: '/admin/clientes',
      icon: 'handshake',
    },
    {
      label: 'Contactos',
      to: '/admin/contactos',
      icon: 'male',
    },
    {
      label: 'Proveedores',
      to: '/admin/proveedores',
      icon: 'people-carry',
    },
    {
      label: 'Maquileros',
      to: '/admin/maquileros',
      icon: 'pencil-ruler',
    },
    {
      label: 'Productos',
      to: '/admin/productos',
      icon: 'cubes',
    },
    {
      label: 'Pedidos',
      to: '/admin/pedidos',
      icon: 'parachute-box',
    },
  ]

  return (
    <div className="white-skin">
      <MDBSideNav
        logo={logo}
        bg={bg}
        mask="strong"
        fixed
        showOverlay
        breakWidth={breakWidth}
        triggerOpening={triggerOpening}
        style={{ transition: 'padding-left 0.3s' }}
      >
        <MDBSideNavLink
          to="/admin"
          onClick={onLinkClick}
          style={{ position: 'absolute', top: 0, width: '100%', height: 150 }}
        />

        <MDBSideNavNav className="NavNav">
          {routes.map(route => (
            <MDBSideNavLink
              style={{ display: isProduction && route.label === 'Pedidos' ? 'none' : '' }}
              key={route.to}
              topLevel
              to={route.to}
              onClick={onLinkClick}
            >
              <MDBIcon icon={`${route.icon} mr-2`} />
              {route.label}
            </MDBSideNavLink>
          ))}

          <MDBSideNavLink topLevel to="/admin/crawlers" onClick={onLinkClick}>
            <MDBIcon icon="fa fa-spider mr-2" />
            Crawlers
          </MDBSideNavLink>
        </MDBSideNavNav>
      </MDBSideNav>
    </div>
  )
}

export default (SideNavigation: SideNavigationProps => React$Node)
