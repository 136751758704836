// @flow
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { MODAL_TOGGLE } from 'redux/constants'
import { getModalState } from 'redux/selectors/stateSelectors'
import { numberUtils } from 'utils'

const ShareModal = () => {
  const dispatch = useDispatch()
  const {
    isOpen,
    data: { hash, status },
  }: ModalState = useSelector(getModalState, shallowEqual)

  const [copyBtn, setCopyBtn] = useState('Copiar')

  const copyToClipboard = () => {
    const copyText: any = document.querySelector('#cotizacionLink')
    copyText.select()
    document.execCommand('copy')
    setCopyBtn('Copiado')
    setTimeout(() => {
      setCopyBtn('Copiar')
      dispatch({ type: MODAL_TOGGLE })
    }, 500)
  }

  if (!hash && !status) {
    return ''
  }

  return (
    <MDBModal
      size={''}
      className="modal-notify modal-success"
      side={false}
      position={''}
      backdrop={true}
      isOpen={isOpen}
      toggle={() => dispatch({ type: MODAL_TOGGLE })}
      inline={false}
      disableFocusTrap={false}
    >
      <MDBModalHeader
        tag="p"
        toggle={() => dispatch({ type: MODAL_TOGGLE })}
        titleClass="heading lead"
      >
        {status === 2 || status === 'Borrador' ? '¡Éxito!' : 'Compartir'}
      </MDBModalHeader>
      <MDBModalBody className="text-center">
        <MDBIcon
          icon={`${
            status === 2 || status === 'Borrador' ? 'check' : 'share-alt'
          } mb-3 animated rotateIn`}
          size="4x"
        />
        <p>
          {status === 2 || status === 'Borrador'
            ? `La cotización fue publicada con éxito. Desde este momento, puedes compartir esta
              cotización con el cliente usando el siguiente enlace:`
            : `Comparte la cotización con el cliente usando el siguiente enlace:`}
          <br />
          <input
            readOnly
            id="cotizacionLink"
            style={{ width: '100%' }}
            value={`${window.location.href
              .split('/')
              .slice(0, 3)
              .join('/')}/cotizacion/${numberUtils.zeroFill(hash)}`}
          />
          {status === 2 || status === 'Borrador' ? (
            <span>
              El cliente podrá ver esta cotización desde cualquier dispositivo y además podrá
              descargarla en formato PDF.
              <br />
              NOTA: Para realizar modificaciones, será necesario crear una recotización.
            </span>
          ) : (
            ''
          )}
        </p>
      </MDBModalBody>
      <MDBModalFooter center>
        <MDBBtn color="success" onClick={() => copyToClipboard()}>
          {copyBtn}
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default (ShareModal: () => React$Node)
