// @flow
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBStep,
  MDBStepper,
  MDBTooltip,
} from 'mdbreact/dist/mdbreact.esm'
import { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { SuperController } from 'controllers'
import { PROVEEDORES_CRAWLERS } from 'redux/constants'
import { getAuthState } from 'redux/selectors'
import useToast from 'utils/hooks/useToast'

import spiderGif from 'assets/img/spider.gif'

const Crawler = () => {
  const auth = useSelector(getAuthState, shallowEqual)
  const Service = new SuperController(auth, 'crawlers')
  const { proveedorId } = useParams()
  const toast = useToast()
  const [sessionId, setSessionId] = useState('')
  const [analizedProducto, setAnalizedProducto] = useState(null)
  const [activeStep, setActiveStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const {
    name,
    website,
    sessionId: sessionIdWebsite,
  } = PROVEEDORES_CRAWLERS.find(proveedor => proveedor.id === +proveedorId)

  const handleSubmit = e => {
    e.preventDefault()
    if (sessionId || !sessionIdWebsite) {
      setIsLoading(true)
      Service.AnalizeProveedor(+proveedorId, sessionId)
        .then(analisisResponse => {
          setAnalizedProducto(analisisResponse)
          setIsLoading(false)
          setActiveStep(2)
        })
        .catch(err => {
          console.error(err)
          toast(`ERROR: Falló la ejecución del análisis`, 'error')
          setIsLoading(false)
          setActiveStep(1)
        })

      const data = new FormData()
      data.append('jsessionid', sessionId)
    }
  }

  const updateProveedor = () => {
    setIsLoading(true)
    setActiveStep(3)
    Service.FullScrapeProveedor(+proveedorId, sessionId)
      .then(({ successMessage, newProducts, deletedItems, newImages, errorMessage }) => {
        if (successMessage) {
          setAnalizedProducto({ successMessage, newProducts, deletedItems, newImages })
          setActiveStep(3)
        } else {
          setAnalizedProducto({ errorMessage })
          setActiveStep(2)
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.error(err)
        toast(`ERROR: Falló la actualización`, 'error')
        setIsLoading(false)
        setActiveStep(1)
      })
  }

  const handleSessionChange = e => setSessionId(e.target.value)

  return (
    <div>
      <MDBStepper icon>
        <MDBStep icon="id-card" stepName="Inicio" onClick={() => setActiveStep(1)} />
        <MDBStep icon="search" stepName="Análisis" />
        <MDBStep icon="check" stepName="Finalizar" />
      </MDBStepper>
      {activeStep === 1 && (
        <>
          <h3>Paso 1</h3>
          <ul>
            {sessionIdWebsite ? (
              <>
                <ol>
                  Visita{' '}
                  <a href={website} target="_blank" rel="noreferrer">
                    {website}
                  </a>{' '}
                  e inicia sesión.
                </ol>
                <ol>
                  Usando el plugin EditThisCookie
                  <MDBTooltip>
                    <MDBBtn color="info" className="btn-sm p-2 d-inline">
                      <MDBIcon icon="question" />
                    </MDBBtn>
                    <div>¿Qué es EditThisCookie?</div>
                  </MDBTooltip>
                  obtén la cookie llamada {`"${sessionIdWebsite}"`}.
                  <MDBTooltip>
                    <MDBBtn color="info" className="btn-sm p-2 d-inline">
                      <MDBIcon icon="question" />
                    </MDBBtn>
                    <div>¿Qué es {sessionIdWebsite}?</div>
                  </MDBTooltip>
                </ol>
                <ol>
                  Copia y pega el valor de la cookie a continuación y da click en &quot;Ejecutar
                  Análisis&quot;.
                </ol>
              </>
            ) : (
              <ol>Da click en &quot;Ejecutar Análisis&quot;.</ol>
            )}
          </ul>
          <form onSubmit={handleSubmit}>
            {sessionIdWebsite && (
              <MDBInput label={sessionIdWebsite} onChange={handleSessionChange} />
            )}
            <MDBBtn
              color="primary"
              type="submit"
              disabled={!sessionIdWebsite ? false : !sessionId ? true : isLoading}
            >
              {isLoading ? 'Ejecutando...' : 'Ejecutar Análisis'}
            </MDBBtn>
            {isLoading && (
              <p>
                <img src={spiderGif} width="200" />
              </p>
            )}
          </form>
        </>
      )}
      {
        <>
          <p>
            ¿Deseas actualizar la Base de Datos de Berit con la información más reciente de {name}?
          </p>
          <p>
            <small>(Esto puede durar varios minutos)</small>
          </p>
          <MDBBtn color="primary" type="submit" onClick={updateProveedor}>
            {isLoading ? 'Actualizando...' : 'Actualizar'}
          </MDBBtn>
        </>
      }
      {activeStep === 2 && analizedProducto && analizedProducto.id ? (
        <>
          <p>
            Se han encontrado cambios en el producto{' '}
            <a href={analizedProducto.href} target="_blank" rel="noopener noreferrer">
              {analizedProducto.productoNombre}
            </a>{' '}
            con BSKU: {analizedProducto.bsku}
          </p>
          <p>
            El precio cambio de ${analizedProducto.precioAnterior} a ${analizedProducto.nuevoPrecio}
          </p>
          <p>
            ¿Deseas actualizar la Base de Datos de Berit con la información más reciente de {name}?
          </p>
          <p>
            <small>(Esto puede durar varios minutos)</small>
          </p>
          <MDBBtn color="primary" type="submit" onClick={updateProveedor}>
            {isLoading ? 'Actualizando...' : 'Actualizar'}
          </MDBBtn>
        </>
      ) : activeStep === 2 && analizedProducto && analizedProducto.noProductsSaved ? (
        <>
          <p>Actualmente no existen productos para este proveedor en la Base de Datos de Berit</p>
          <p>
            ¿Deseas actualizar la Base de Datos de Berit con la información más reciente de {name}?
          </p>
          <p>
            <small>(Esto puede durar varios minutos)</small>
          </p>
          <MDBBtn color="primary" type="submit" onClick={updateProveedor}>
            {isLoading ? 'Actualizando...' : 'Actualizar'}
          </MDBBtn>
        </>
      ) : (
        activeStep === 2 && (
          <>
            <p>Atención: {analizedProducto?.errorMessage || 'Desconocido'}</p>
            <p>Vuelve a intentarlo mas tarde.</p>
          </>
        )
      )}
      {activeStep === 3 && (
        <>
          {analizedProducto?.successMessage ? (
            <>
              <h2>Éxito</h2>
              <h4>El análisis se ejecutó exitosamente.</h4>
              <ol>
                <li>{analizedProducto.successMessage}</li>
                <li>{analizedProducto.newProducts}</li>
                <li>{analizedProducto.deletedItems}</li>
                <li>{analizedProducto.newImages}</li>
                <li>Los precios en la Base de Datos Berit han sido actualizados.</li>
              </ol>
              <p />
            </>
          ) : analizedProducto?.newProvider ? (
            <>
              <h2>Éxito</h2>
              <h4>El proveedor {name} se actualizo exitosamente.</h4>
              <ol>
                <li>{analizedProducto.newProducts}</li>
                <li>{analizedProducto.newImages}</li>
              </ol>
              <p />
            </>
          ) : (
            <h5>La base de datos se esta actualizando, esto podría tardar varios minutos...</h5>
          )}
        </>
      )}
    </div>
  )
}

export default (Crawler: () => React$Node)
