// @flow
import BaseController from './_baseController'
import { numberUtils } from 'utils'

class SuperController extends BaseController {
  constructor(auth: AuthState | null = {}, entity: string) {
    super()
    this.auth = auth
    this.controllerEndpoint = entity
  }

  async GetAll(filter: Filter = {}) {
    const response = await super.GetAll.call(this, filter)
    return await response
  }

  async GetById(entityId: number) {
    const response = await super.GetById.call(this, entityId)
    return await response
  }

  async GetByHash(hash) {
    const request = new Request(
      `${this.API_ENDPOINT}${this.controllerEndpoint}/getByHash/?hash=${numberUtils.zeroFill(
        hash,
        10
      )}`,
      {
        method: 'GET',
      }
    )
    return await this._handleRequest(request)
  }

  async Add(entityForm): Promise<number> {
    const response = await super.Add.call(this, entityForm)
    return await response
  }

  async Update(entityId: number, entityForm) {
    const response = await super.Update.call(this, entityId, entityForm)
    return await response
  }

  async AnalizeProveedor(proveedorId: number, sessionId: string) {
    const request = new Request(
      `${this.API_ENDPOINT}${this.controllerEndpoint}/analisis/?proveedorId=${proveedorId}&sessionId=${sessionId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${this.auth.accessToken}`,
        },
      }
    )
    return await this._handleRequest(request)
  }

  async FullScrapeProveedor(proveedorId: number, sessionId: string) {
    const request = new Request(
      `${this.API_ENDPOINT}${this.controllerEndpoint}/fullscrape/?proveedorId=${proveedorId}&sessionId=${sessionId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${this.auth.accessToken}`,
        },
      }
    )
    return await this._handleRequest(request)
  }

  async Publish(id) {
    const request = new Request(
      `${this.API_ENDPOINT}${this.controllerEndpoint}/publish/?id=${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Token ${this.auth.accessToken}`,
        },
      }
    )
    return await this._handleRequest(request)
  }

  async Delete(entityId: number): Promise<1> {
    const response = await super.Delete.call(this, entityId)
    return await response
  }
}

export default SuperController
