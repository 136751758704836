// @flow
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBIcon,
  MDBRow,
} from 'mdbreact/dist/mdbreact.esm'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import Confirm from 'components/Confirm'
import { MODAL_SET, MODAL_TOGGLE } from 'redux/constants'
import { usePublish } from 'utils'

type FooterVerType = {
  id: number,
  hash: number,
  status: number,
  isPedido: boolean,
  cotizacion: Cotizacion,
}

const FooterVer = ({ cotizacion, isPedido = false, id, hash = 0, status }: FooterVerType) => {
  const [statusState, setStatusState] = useState(0)
  const cotizacionPublish = usePublish('cotizaciones')
  const [confirmStatus, setConfirmStatus] = useState(false)
  const dispatch = useDispatch()
  const { cotizacionHash } = useParams()
  const history = useHistory()

  useEffect(() => {
    setStatusState(status)
  }, [status])

  const toggle = () => {
    setConfirmStatus(false)
    if (statusState === 1) {
      dispatch({ type: MODAL_SET, payload: { status: statusState, hash } })
      dispatch({ type: MODAL_TOGGLE })
    } else {
      cotizacionPublish(id, setStatusState, hash)
    }
  }

  const editRoute = isPedido ? 'pedidos/seguimiento' : 'cotizaciones/editar'
  const fecha = isPedido ? 'fechaPedido' : 'fecha'

  const vigencia = moment(cotizacion[fecha])
    .add(cotizacion.diasVigencia ?? 8, 'd')
    .format('dddd, DD [de] MMMM [de] YYYY')
  const disclaimer = [
    {
      title: 'PRECIOS',
      description:
        '<b>Precios expresados en Pesos Mexicanos, no incluyen IVA y estan sujetos a variaciones sin previo aviso.</b>',
    },
    {
      title: 'EXISTENCIAS',
      description: 'Artículos sujetos a existencias al momento de fincar el pedido.',
    },
    {
      title: 'VIGENCIA DE LA COTIZACIÓN',
      description: `El precio se respeta hasta el día <b>${vigencia}</b>, siempre y cuando no exista una variación considerable en el tipo de cambio, de ser así se les notificará con previa anticipación.`,
    },
    {
      title: 'IMPORTANTE',
      description:
        'Consulte las <b>CONDICIONES COMERCIALES</b> que se muestran a continuación, a tomar en cuenta para su pedido.',
    },
  ]
  const condicionesComerciales = [
    {
      icon: 'clock',
      iconType: 'far',
      title: 'TIEMPO DE ENTREGA',
      description: `<b>${cotizacion.tiempoEntrega}</b> a partir del día hábil siguiente a la aprobación del diseño virtual y confirmación estricta de recepción de la OS/OC/VO.BO.`,
    },
    {
      icon: 'hourglass-half',
      iconType: 'fas',
      title: 'PEDIDOS URGENTES',
      description:
        'Aplicará un <b>incremento de precio entre el 15% al 25%</b> dependiendo del tipo de proyecto. IMPORTANTE: El envío del diseño del cliente y la aprobación de la muestra virtual por parte del cliente se debe hacer en el menor tiempo posible para agilizar el proceso del pedido.',
    },
    {
      icon: 'calendar-alt',
      iconType: 'fas',
      title: 'LUGAR Y FECHA DE ENTREGA',
      description:
        'El acordado con el cliente. Al momento de fincar el pedido <b>confirma a tu ejecutivo de ventas fecha, lugar y contacto de entrega</b> (en caso de que el contacto a entregar sea diferente al solicitante).',
    },
    {
      icon: 'tasks',
      iconType: 'fas',
      title: 'CONDICIONES DE PAGO',
      description: 'De acuerdo a lo <b>estipulado en la orden de compra del cliente</b>.',
    },
    {
      icon: 'money-bill',
      iconType: 'fas',
      title: 'FACTURACIÓN Y FORMA DE PAGO',
      description:
        'Al momento de fincar el pedido confirma con su ejecutivo de ventas el <b>uso de CFDI, forma de pago y método de pago</b>, asi como si  se debe facturar a más de una razón social y especificar  los artículos y montos de cada razon social a facturar.',
    },
    {
      icon: 'pencil-alt',
      iconType: 'fas',
      title: 'DISEÑO DEL CLIENTE',
      description: `Favor de envíar el <b>arte en formato editable</b>: Illustrator o PDF vectorizado (no imagen).<br/>
      <b>* En caso de no contar con el arte en vectores</b>: La elaboración del arte tendrá un costo extra acorde a la complejidad del logo o diseño de cliente. Una vez recibido el diseño, su ejecutivo de ventas cotejará con el equipo de diseño cada caso e informará al cliente el costo del mismo.`,
    },
    {
      icon: 'image',
      iconType: 'far',
      title: 'MUESTRA VIRTUAL',
      description:
        'Una vez <b>confirmada le recepción del logo y/o diseño se realizara una muestra virtual</b>, misma que <b>deberá ser aprobada por el cliente a entera satisfacción para poder iniciar producción</b>: Dicha muestra virtual permite al cliente visualizar la apariencia que tendrá su logotipo aplicado al producto en tamaño, color, posición, técnica, cantidad y/o tallas. Este proceso nos permite detectar cualquier posible corrección previo a la producción. <b>Una vez aprobada la muestra virtual, cualquier cambio tendrá un cargo extra</b> de acuerdo al proyecto. Generar una muestra virtual puede tomar de 1 a 3 días según la carga de trabajo en BERIT.',
    },
    {
      icon: 'briefcase',
      iconType: 'fas',
      title: 'MUESTRA FÍSICA',
      description:
        '<b>En caso de fincar pedido, el costo de la muestra se absorbe en el pedido, de lo contrario será con costo</b> (Siempre y cuando se haya personalizado el producto con el logo o diseño del cliente: impresión / bordado / grabado, de lo contrario, únicamente se recolectará la muestra, o se pedirá al cliente que la envie de vuelta. En caso de no devolver la muestra, esta se cobrará al cliente).',
    },
    {
      icon: 'ban',
      iconType: 'fas',
      title: 'CANCELACIONES',
      description: `<b>En pedidos urgentes no hay cancelaciones una vez confirmado el proyecto</b>.<br/>
      Cualquier cancelación despues de 24 hrs de confirmado el pedido tendrá un cargo del 30%  al 40% sobre el monto total del pedido cancelado en caso de aplicar una cancelación. Consulta con tu ejecutivo si tu padido es candidato a cancelación.`,
    },
  ]

  return (
    <>
      {!cotizacionHash && statusState !== 0 && (
        <MDBFooter
          className="pt-0 fixed-bottom"
          style={{ backgroundColor: statusState === 1 && 'rgb(0, 193, 82)' }}
        >
          <MDBContainer>
            <MDBRow around className="align-items-center my-2 my-md-4 text-center">
              <MDBCol size="12" md="auto">
                <span style={{ fontSize: '2rem' }}>
                  Status: {statusState === 1 ? 'Publicado - Ver. 1' : 'Borrador'}
                </span>
              </MDBCol>
              {statusState === 2 ? (
                <MDBCol size="auto">
                  <MDBBtn
                    gradient="cloudy-knoxville"
                    size="md"
                    className="text-black"
                    onClick={() => history.push(`/admin/${editRoute}/${id}`)}
                  >
                    <MDBIcon icon="edit" className="mr-1" />
                    Editar
                  </MDBBtn>
                </MDBCol>
              ) : (
                <MDBCol size="auto">
                  <MDBBtn
                    gradient="cloudy-knoxville"
                    size="md"
                    className="text-black"
                    onClick={() => history.push(`/admin/${editRoute}/${id}`)}
                  >
                    <MDBIcon icon="file-invoice-dollar" className="mr-1" size="lg" />
                    Recotizar
                  </MDBBtn>
                </MDBCol>
              )}
              <MDBCol size="auto">
                <MDBBtn
                  gradient="berit"
                  size="md"
                  className="text-black"
                  onClick={() => {
                    statusState === 1 ? toggle() : setConfirmStatus(true)
                  }}
                >
                  {statusState === 1 ? 'Compartir' : 'Publicar'}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      )}

      {!isPedido && (
        <MDBContainer className="CotizacionCondicionesComerciales">
          <section>
            {disclaimer.map(condicion => {
              return (
                <MDBRow
                  key={condicion.title}
                  className="features-big pb-4 mt-4 align-items-center text-center text-md-left"
                >
                  <MDBCol size="12" md="1" />
                  <MDBCol size="12" md="3">
                    <h5 className="feature-title font-weight-bold">{condicion.title}</h5>
                  </MDBCol>
                  <MDBCol size="12" md="8">
                    <span dangerouslySetInnerHTML={{ __html: condicion.description }} />
                  </MDBCol>
                </MDBRow>
              )
            })}
          </section>

          <div className="bars">
            <div className="berit-gradient" />
            <h2 className="text-center text-md-left">CONDICIONES COMERCIALES</h2>
          </div>

          <section className="mb-4">
            {condicionesComerciales.map(condicion => {
              return (
                <MDBRow
                  key={condicion.title}
                  className="features-big align-items-center text-center text-md-left"
                >
                  <MDBCol size="12" md="1">
                    <MDBIcon
                      className={condicion.iconType}
                      icon={`${condicion.icon} mb-3 berit-text`}
                      size="2x"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="3">
                    <h5 className="feature-title font-weight-bold">{condicion.title}</h5>
                  </MDBCol>
                  <MDBCol size="12" md="8">
                    <span dangerouslySetInnerHTML={{ __html: condicion.description }} />
                  </MDBCol>
                </MDBRow>
              )
            })}
          </section>
        </MDBContainer>
      )}
      <Confirm onClose={() => setConfirmStatus(false)} state={confirmStatus} okHandler={toggle}>
        <div className="text-center p-4">
          <div>
            <i className="text-muted mb-4 fas fa-exclamation-triangle fa-3x" />
          </div>
          <h5>¿Deseas publicar esta cotización?</h5>
          <p>
            Al publicarla podrás compartirla con el cliente. Para realizar modificaciones, será
            necesario crear una recotización.
          </p>
        </div>
      </Confirm>
    </>
  )
}

export default FooterVer
